import React from "react"
import { graphql, Link } from "gatsby"
// import SEO from "../components/SEO"
import Layout from '../layouts/Layout'
import SubpageHeader from '../components/SubpageHeader'
import SubpageItem from '../components/SubpageItem'
import styled from "styled-components"
import { gsap } from "gsap";

export const query = graphql`
{
    prismic {
        allAbouts {
            edges {
                node {
                short_footer_text
                }
            }
        }
        allProjectssubpages {
            edges {
                node {
                    intro_text_to_projects
                    ordered_projects {
                        link_to_project {
                            ... on PRISMIC_Project {
                                _meta{
                                    uid
                                }
                                project_title
                                project_description
                                photos {
                                    photo
                                    photoSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 620, quality: 100) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`

const MainContainer = styled.div`
    margin: 96px 15vw 0;
    display: flex;

    @media screen and (max-width: 1100px) {
        margin: 96px 10vw 0;
        justify-content: space-between;
    }

    @media screen and (max-width: 1000px) {
        margin: 96px 2.43vw 0;
        justify-content: space-around;
    }

    @media screen and (max-width: 750px) {
        margin: 75px 4vw 0;
    }
`

const ItemsContrainer = styled.div`
    width: 45vw;

    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`

const LinksContainer = styled.div`
    width: 25vw;
`

const LinksInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const LinkTag = styled(Link)`
    text-decoration: none;
    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
    letter-spacing: 2px;
    opacity: 0.5;

    @media screen and (min-width: 1600px) {
        font-size: 18px;
    }
`

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: null,
            linksDisplay: { display: 'block' }
        }

        this.scrollMagicAnimations = this.scrollMagicAnimations.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        let windowWidth = window.innerWidth;
        this.setState({ screenWidth: windowWidth });
        if (windowWidth > 1000 ) {
            this.setState({ linksDisplay: { display: 'block' } });
        } else {
            this.setState({ linksDisplay: { display: 'none' } });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
        this.updateWindowDimensions();

        if(window.innerWidth > 1000) {
            this.scrollMagicAnimations();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    scrollMagicAnimations() {
        const projectssubpageData = this.props.data.prismic.allProjectssubpages.edges.slice(0, 1).pop()
        const orderedProjects = projectssubpageData.node.ordered_projects;

        if (typeof window !== 'undefined') {
            import('scrollmagic').then((ScrollMagic) => {
                let controller = new ScrollMagic.Controller();
                const itemsContainerHeight = document.querySelector('#items-container').clientHeight;
                const linksContainerHeight = document.querySelector('#links-inner-container').clientHeight;
                const height = itemsContainerHeight - linksContainerHeight;

                new ScrollMagic.Scene({ triggerElement: "#main-container", triggerHook: 0.25, duration: height })
                    .setPin("#links-container")
                    // .addIndicators({ name: "main" })
                    .addTo(controller);

                orderedProjects.forEach(project => {
                    let uidOfProject = project.link_to_project._meta.uid;
                    let projectDivHeight = document.querySelector(`.${uidOfProject}`).clientHeight;

                    new ScrollMagic.Scene({ triggerElement: `.${uidOfProject}`, triggerHook: 0.4, duration: projectDivHeight })
                        .setClassToggle(`#${uidOfProject}`, "active")
                        // .addIndicators({ name: "project" })
                        .addTo(controller);
                });
            });
        }
    }

    renderDescription = (description) => {
        if(description != null) return description[0].text
        return ""
    }

    render() {
        const footerData = this.props.data.prismic.allAbouts.edges.slice(0, 1).pop()
        const projectssubpageData = this.props.data.prismic.allProjectssubpages.edges.slice(0, 1).pop()
        if (!projectssubpageData || !footerData) return null

        return (
            <>
                {/* <SEO title="Projects" /> */}
                <Layout
                    location={this.props.location}
                    footerShortText={footerData.node.short_footer_text[0].text}
                    footerLinksArray={[
                        {
                            name: 'Events',
                            URL: '/events/'
                        },
                        {
                            name: 'Diary',
                            URL: '/diary/'
                        },
                        {
                            name: 'Home',
                            URL: '/'
                        }
                    ]}>
                    <div className="max-width-1600">
                        {/* Subpage header */}
                        <SubpageHeader headline={"PROJECTS"} introTextArray={projectssubpageData.node.intro_text_to_projects}></SubpageHeader>

                        <MainContainer id="main-container">
                            <div style={this.state.linksDisplay}>
                                {/* Links to the projects */}
                                <LinksContainer id="links-container">
                                    <LinksInnerContainer id="links-inner-container">
                                        {projectssubpageData.node.ordered_projects.map(project => (
                                            <LinkTag
                                                to={"/projects/" + project.link_to_project._meta.uid}
                                                key={project.link_to_project._meta.uid}
                                                id={project.link_to_project._meta.uid}>
                                                {project.link_to_project.project_title[0].text}
                                            </LinkTag>
                                        ))}
                                    </LinksInnerContainer>
                                </LinksContainer>
                            </div>   

                            {/* All projects */}
                            <ItemsContrainer id="items-container">
                                {projectssubpageData.node.ordered_projects.map(project => (
                                    <SubpageItem
                                        itemURL={"/projects/" + project.link_to_project._meta.uid}
                                        photoURL={project.link_to_project.photos[0].photoSharp.childImageSharp.fluid}
                                        title={project.link_to_project.project_title[0].text}
                                        description={this.renderDescription(project.link_to_project.project_description)}
                                        divClass={project.link_to_project._meta.uid}
                                        key={project.link_to_project._meta.uid}>
                                    </SubpageItem>
                                ))}
                            </ItemsContrainer>
                        </MainContainer>
                    </div>
                </Layout>
            </>
        )
    }
}

export default Projects